import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, withPrefix } from 'gatsby'
import './BlogRoll.sass';

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-4" key={post.id}>
              <a
                href={post.fields.slug}
                className= {`card-blog ${post.frontmatter.featuredpost ? 'is-featured' : ''
              }`}
              >
                <div
                  className="thumb"
                  style={{
                    backgroundImage: `url(${post.frontmatter.featuredimage?.publicURL ? post.frontmatter.featuredimage.publicURL : withPrefix("/") + "img/skeleton-blog.png"})`,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px'
                  }}
                />
                <article className='blog-article' >
                  <h3 className='blog-title'>
                    {post.frontmatter.title}
                  </h3>
                  <p className='blog-p'>
                    {post.excerpt}
                  </p>
                  <span className='blog-span' >{post.frontmatter.date}</span>
                </article>
              </a>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
