import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import SEO from "../components/seo"
import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll/BlogRoll'
import useSiteMetadata from "../components/SiteMetadata";
import { FaInstagram, FaGithub, FaTwitter, FaLinkedin, } from 'react-icons/fa';


export const IndexPageTemplate = ({
    image,
    title,
    heading,
    subheading,
    mainpitch,
    description,
    intro,
}) => (
    <div>
        <div
            className="full-width-image margin-top-0"
            style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`,
                backgroundPosition: `top left`,
                backgroundAttachment: `fixed`,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    height: '150px',
                    lineHeight: '1',
                    justifyContent: 'space-around',
                    alignItems: 'left',
                    flexDirection: 'column',
                }}
            >
                <h1
                    className="has-text-weight-medium is-size-3-mobile is-size-2-tablet has-text-centered"
                    style={{
                        boxShadow:
                            '#49509D 0.5rem 0px 0px, #49509D -0.5rem 0px 0px',
                        backgroundColor: '#49509D',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em',
                        borderRadius: '20px',

                    }}
                >
                    {title}
                </h1>
                <h2
                    className="is-size-4-mobile is-size-3-tablet is-size-3  has-text-centered"
                    style={{
                        boxShadow:
                            '#49509D 0.5rem 0px 0px, #49509D -0.5rem 0px 0px',
                        backgroundColor: '#49509D',
                        color: 'white',
                        padding: '0.25em',
                        borderRadius: '20px',
                    }}
                >
                    {subheading}
                </h2>
                <div className="columns is-centered has-text-centered">
                    <div className="column social-banner is-centered">
                        <a title="github" href="https://github.com/crisjc6">
                            <FaGithub style={{
                                width: '18px',
                                height: '18px'
                            }} />
                        </a>
                        <a title="twitter" href="https://twitter.com/crisjc8">
                            <FaTwitter style={{
                                width: '18px',
                                height: '18px'
                            }} />
                        </a>
                        <a title="instagram" href="https://instagram.com/crisweb.me">
                            <FaInstagram style={{
                                width: '18px',
                                height: '18px'
                            }} />
                        </a>
                        <a title="Linkeding" href="https://www.linkedin.com/in/cristhian-jumbo-748934180">
                            <FaLinkedin style={{
                                width: '18px',
                                height: '18px'
                            }} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <section className="container">
            <div className="section">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="content">
                            <div className="content">
                                    <h3 className="has-text-weight-semibold is-size-3">{mainpitch.title}</h3>
                                    <p className="is-size-6-mobile is-size-5-touch is-size-4">{mainpitch.description}</p>
                            </div>
                            <div className="columns">
                                <div className="column is-12">
                                    <h3 className="has-text-weight-semibold is-size-3">
                                        {heading}
                                    </h3>
                                    <p className='is-size-6-mobile is-size-5-touch is-size-4' >{description}</p>
                                </div>
                            </div>
                            <Features gridItems={intro.blurbs} />
                            <div className="column is-12">
                                <h3 className="has-text-weight-semibold is-size-3">
                                    Ultimos Post
                                </h3>
                                <BlogRoll />
                                <div className="column is-12 has-text-centered">
                                    <Link className="btn" to="/blog">
                                        Leer Más →
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    site: PropTypes.shape({
        siteMetadata: PropTypes.object
    }),
    description: PropTypes.string,
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
}

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
    const { siteUrl, description } = useSiteMetadata()
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Cris Web",
        "description": description,
        "url": siteUrl,
        "logo": "https://www.crisweb.me/static/slogan-n-back-dbaf0b2726fe6bdbe648b3f900f064a2.svg"
    }
    return (
        <Layout>
            <SEO title="Home" schemaMarkup={schema}
            />
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
                intro={frontmatter.intro}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
